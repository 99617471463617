import React from 'react'
import { Helmet } from "react-helmet"

import Footer from '../partials/footer.js'
import "../styles/app.scss"

export default class MarketingLayout extends React.Component {
  render() {
    const title = `${this.props.title} | ${this.props.shortDescription}`
    return (
      <>
        <Helmet title={title}
                meta={[
                  {name: 'robots', content: 'index,follow'},
                  {name: 'author', content: 'Carrick'},
                  {name: 'og:title', content: title},
                  {name: 'og:type', content: 'website'},
                  {name: 'og:url', content:'https://carrick.joewegner.com/'},
                  {name: 'viewport', content: 'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0'},
                  {name: 'description', content: this.props.description},
                  {name: 'og:description', content: this.props.description}
                ]}>
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-178261279-1"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                window.dataLayer.push(arguments)
              }
              gtag('js', new Date());

              gtag('config', 'UA-178261279-1');
            `}
          </script>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,800;1,300;1,400;1,600;1,800&display=swap" rel="stylesheet" />
        </Helmet>

        {this.props.children}

        <Footer />
      </>
    )
  }
}