import React from 'react'

import BaseLayout from '../layouts/base.js'

export default class Terms extends React.Component {
  render() {
    return (   
      <BaseLayout title="Carrick Issues"
      description="Track GitHub Issues directly from Help Scout. Carrick Issues integrates your Help Scout mailbox directly with GitHub so your team can coordinate faster and serve customers better."
      shortDescription="Track GitHub Issues directly from Help Scout">
        <div class="error-body">
          <h1>
            <img src="/img/logo.svg" alt="Carrick Integrations" />
            Carrick
          </h1>
          <h2>Wait, what?</h2>
          <p>It looks like the page you're looking for doesn't exist! Best option would be to <a href="https://carrick.joewegner.com">head to our homepage</a> or reload <a href="https://carrick-processor.herokuapp.com/config/issues">your app</a>.</p>
        </div>  
      </BaseLayout>
    )
  }
}